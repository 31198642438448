import { BOND_EMBLEM_TIERS, POTENTIAL_EMBLEM_TIERS, RAID_MINSCORE } from "Constants/Common";
import { IArmorType } from "Interfaces/IRaidRankFilter";
import { ITableRow } from "Interfaces/ITableRow";
import { ITeamInfoJsonItem } from "Interfaces/ITeamInfoJsonItem";
import Student from "Models/Student";
import StudentFilter from "Models/StudentFilter";
import StudentMap from "Models/StudentMap";
import TeamFormation from "Models/TeamFormation";
import { IGrandRaidInfo } from "Views/GrandRaidData";
interface IScore {
	RED: number;
	YELLOW: number;
	BLUE: number;
	PURPLE: number;
	ALL: number;
}
export default class GrandRaidRankTsvRow 

implements ITableRow
{
	username: string;
	rank: number;
	uid: string | number;
	score: number;
	scores: IScore;
	level: number;
	// strikers: Student[] = [];
	// specials: Student[] = [];
	// teams: TeamFormation[];
	teams: {
		RED: TeamFormation[];
		YELLOW: TeamFormation[];
		BLUE: TeamFormation[];
		PURPLE: TeamFormation[];
		ALL: TeamFormation[];
	}
	tier: number;
	profilePictureId: number;
	// `5{student.Id}{i}` = Student Bond Tier i (20/50/100)
	// `8{student.Id}{i}` = Student Potential/LB Tier i (25/?/?)
	emblemId: string;
	emblem: string;
	knownEmblem: boolean = false;
	// getHeaders: () => string[];
	toArray: () => string[];
	toKeyValues: () => { [key: string]: string; };
	getHeaders() {
		return [
			"Rank",
			"Score",
			"Sensei Level",
			"Sensei",
			"Unit",
			"Students"
		]
	}
	export():string[][] {
		let name = this.username ?? "#"+this.rank;
		const teams = [];
		for (const team in this.teams) {
			teams.push([
				this.rank.toLocaleString(),
				this.score.toLocaleString(),
				this.level.toLocaleString(),
				name,
				"Unit "+((parseInt(team))+1),
				this.teams[team].toString()
			]);
		}

		return teams;
	}
	getArmors(): IArmorType[] {
		const armors = ["RED",
			"YELLOW",
			"BLUE",
			"PURPLE",] as IArmorType[];
		return armors.filter(armor => this.scores[armor] >= 0 && armor !== "ALL").sort((a,b)=>armors.indexOf(a) - armors.indexOf(b))
	}
// TODO: IArmorType
	getDifficulty(teamType: IArmorType) {
		const score = this.scores[teamType];
		if (score >= RAID_MINSCORE.LUNA)
			return "LUNA";
		if (score >= RAID_MINSCORE.TOR)
			return "TOR";
		if (score >= RAID_MINSCORE.INS)
			return "INS";
		if (score >= RAID_MINSCORE.EXT)
			return "EXT";
		if (score >= RAID_MINSCORE.HC)
			return "HC";
		if (score >= RAID_MINSCORE.VH)
			return "VH";
		if (score >= RAID_MINSCORE.H)
			return "H";
		return "N";
	}
	getDifficultyNumber(teamType: IArmorType):number {
		const score = this.scores[teamType];
		if (score >= RAID_MINSCORE.LUNA)
			return 7;
		if (score >= RAID_MINSCORE.TOR)
			return 6;
		if (score >= RAID_MINSCORE.INS)
			return 5;
		if (score >= RAID_MINSCORE.EXT)
			return 4;
		if (score >= RAID_MINSCORE.HC)
			return 3;
		if (score >= RAID_MINSCORE.VH)
			return 2;
		if (score >= RAID_MINSCORE.H)
			return 1;
		return 0;
	}

	hasTeamData() {
		return this.teams && this.teams.ALL.length > 0;
	}
	findStudentById(student: string, teamType: IArmorType) {
		let s = new StudentMap().getProperName(student) ?? "EMPTY";
		if (this.hasTeamData()) {
			for(const team of this.teams[teamType]) {
				if (team.getNames().indexOf(s) >= 0)
					return true;
			}
		}
		return false;
	}
	findStudent(student: StudentFilter, teamType: IArmorType) {
		if (this.hasTeamData()) {
			for(const team of this.teams[teamType]) {
				for(const member of team.strikers) {
					if (student.matches(member))
						return true;
				}
				for(const member of team.specials) {
					if (student.matches(member))
						return true;
				}
			}
		}
		return false;
	}

	getIcon() {
		const icon = new StudentMap().getIcon(this.profilePictureId);
		if (icon?.length)
			return icon;
		return "/img/DefaultStudent.jpg";
	}
	getTier() {
		switch (this.tier) {
			case 1: return "Bronze";
			case 2: return "Silver";
			case 3: return "Gold";
			case 4: return "Platinum";
			default: return "UNKNOWN";
		}
	}
	getTierIcon() {
		return `/img/Raid_RankIcon_0${this.tier}.png`;
	}
	getDisplayName() {
		if (this.knownEmblem) {
			return `Lv${this.level} ${this.username}\n[${this.emblem}]`;
		}
		return `Lv${this.level} ${this.username}`;
	}

	toString() {
		return `#${this.rank}: Score: ${this.score} [${this.scores.RED} / ${this.scores.YELLOW} / ${this.scores.BLUE}]`;
	}

	setName(name:string) {
		this.username = name;
	}

	setEmblem(emblemId:string) {
		this.emblemId = emblemId
		this.emblem = this.parseEmblem();
	}
	getEmblem() {
		return this.emblem;
	}
	parseEmblem() {
	// `5{student.Id}{i}` = Student Bond Tier i (20/50/100)
	// `8{student.Id}{i}` = Student Potential/LB Tier i (25/?/?)
		this.knownEmblem = false;
		if (this.emblemId.length < 3) return "";
		const emblemType = this.emblemId[0];
		const emblemTier = this.emblemId[this.emblemId.length-1];
		const studentId = this.emblemId.substring(1,this.emblemId.length-1)
		const studentName = new StudentMap().getName(studentId) ?? "Unknown Student";
		switch (emblemType) {
			case "5": 
			this.knownEmblem = true;
			return `${studentName} Bond ${BOND_EMBLEM_TIERS[emblemTier]}`;
			case "8": 
			this.knownEmblem = true;
			return `${studentName} LB All Lv ${POTENTIAL_EMBLEM_TIERS[emblemTier]}`;
			default: return "Unknown Title"
		}
	}
	withTeams(data: ITeamInfoJsonItem[]) {
		this.teams = {
			RED: [],
			YELLOW: [],
			BLUE: [],
			PURPLE: [],
			ALL: [],
		};
		let ctr = 1;
		for(const team of data) {
			let i=0, j=0;
			const formation = new TeamFormation(
				[
					Student.fromRaidRanks(team.team.strikers[i++]),
					Student.fromRaidRanks(team.team.strikers[i++]),
					Student.fromRaidRanks(team.team.strikers[i++]),
					Student.fromRaidRanks(team.team.strikers[i++]),
				],
				[
					Student.fromRaidRanks(team.team.specials[j++]),
					Student.fromRaidRanks(team.team.specials[j++]),
				]
			);
			formation.setUnit(ctr++);
			if (team.raid.indexOf("_HeavyArmor") >= 0) {
				formation.setType("YELLOW");

			} else if (team.raid.indexOf("_LightArmor") >= 0) {
				formation.setType("RED");

			} else if (team.raid.indexOf("_Unarmed") >= 0) {
				formation.setType("BLUE");
			} else if (team.raid.indexOf("_Elastic") >= 0)  {
				formation.setType("PURPLE");
			}
			this.teams.ALL.push(formation);
			this.teams[formation.type].push(formation);
			
			
		}
	}
	correctScoreColors(info: IGrandRaidInfo) {
		const armors = Object.keys(info.playable);
		const defarmor = ["RED", "YELLOW", "BLUE", "PURPLE"];
		const tararmor = [];
		for (let i=0; i<3; i++) {
			const armor = armors[i];
			if (armor.indexOf("_HeavyArmor") >= 0) {
				tararmor.push("YELLOW");
			} else if (armor.indexOf("_LightArmor") >= 0) {
				tararmor.push("RED");
			} else if (armor.indexOf("_Unarmed") >= 0) {
				tararmor.push("BLUE");
			} else if (armor.indexOf("_Elastic") >= 0)  {
				tararmor.push("PURPLE");
			}
		}
		tararmor.sort((a,b)=>defarmor.indexOf(a) - defarmor.indexOf(b))
		let newScores:IScore = {
			RED: -1,
			BLUE: -1,
			PURPLE: -1,
			YELLOW: -1,
			ALL: this.scores.ALL
		}
		for (let i=0; i<3; i++) {
			newScores[tararmor[i]] = this.scores[defarmor[i]]
		}

		this.scores = newScores;
	}

	static parse(raw:string) {
		const data = raw.split('\t');
		if (data.length < 2) return undefined;
		const row = new GrandRaidRankTsvRow();
		let i=0;
		row.rank = parseInt(data[i++]);
		row.uid = data[i++];
		row.profilePictureId = parseInt(data[i++].trim());
		row.score = parseInt(data[i++]);
		row.scores = {
			RED: parseInt(data[i++]),
			YELLOW: parseInt(data[i++]),
			BLUE: parseInt(data[i++]),
			PURPLE: -1,
			ALL: row.score,
		}
		row.level = parseInt(data[i++]);
		row.tier = parseInt(data[i++]);
		return row;
	}
}