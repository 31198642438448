import RaidDataFilters from "Components/Filters/RaidDataFilters";
import RaidLeaderboardRowRenderer from "Components/ModelRenderers/RaidLeaderboardRowRenderer";
import MyLoadingSpinner from "Components/MyLoadingSpinner";
import MyPagination from "Components/MyPagination";
import { RaidFileListDropDown } from "Components/RaidFileListDropDown";
import RaidTerrainImage from "Components/RaidTerrainImage";
import { TagBadge } from "Components/TagBadge";
import { BUCKET_HOST, CURRENT_MAX_SCORE, RAID_MAXSCORE, RAID_MINSCORE, SCORE_CALCULATORS } from "Constants/Common";
import { IPoint } from "Interfaces/IPoint";
import IStyle from "Interfaces/IStyle";
import RaidReportModal from "Modals/RaidReportModal";
import RaidRankTsv from "Models/RaidRankTsv";
import RaidRankTsvRow from "Models/RaidRankTsvRow";
import RaidScore from "Models/RaidScore";
import Student from "Models/Student";
import Tsv from "Models/Tsv";
import RaidReportItem from "Reports/RaidReportItem";
import RaidStudentReportItem from "Reports/RaidStudentReportItem";
import RaidTeamReportItem from "Reports/RaidTeamReportItem";
import CommonRaidReportItem from "Reports/ScoreReportItem";
import { getJson, regexEscape } from "Utils/Common";
import { saveAs } from 'file-saver';
import React, { useEffect, useMemo, useState } from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { IRaidRankFilter } from "../Interfaces/IRaidRankFilter";

const style:IStyle = {
	button: {marginRight: 5},
}
interface IRaidInfo {
	season: number,
	playable: {[name:string]: any},
	start: string,
	end: string,
	seasonType: any
}
function WarningMessage() {
	return (
		<Alert variant="warning" dismissible>
			Note that formatting is still a mess. I'm supposed to improve this but Time Doko? Suffer. 
			<br></br>Added Compact Mode. Also added a Loading Spinner when selecting data source and applying filters. 
			<br></br>Fixed issue when ctrl+f shows too many results in Analytics. 
			<br></br>Next steps are to find a better way to display the Loading Spinner and to apply it on Analytics Generation.
			<br></br>Also added Scatter Charts for Students in Full Analytics along with Senseis with Student in Attendance Report
		</Alert>
	)
}
// export let RAID_DATA_SCORE_CALCULATORS:RaidScore[];
export default function RaidData() {
	const [data, setData] = useState<RaidRankTsv>(null);
	const [isReportVisible, setIsReportVisible] = useState(false);
	const [displayedData, setDisplayedData] = useState<RaidRankTsvRow[]>(null);
	const [displayedParticipationReport, setDisplayedParticipationReport] = useState<RaidStudentReportItem[]>([]);
	const [studentDataReport, setStudentDataReport] = useState<RaidStudentReportItem[]>([]);
	const [teamReport, setTeamReport] = useState<RaidTeamReportItem[]>([]);
	const [scoreReport, setScoreReport] = useState<CommonRaidReportItem>(null);
	const {server, season} = useParams();
	const [date, setDate] = useState<string>("");
	const [isLoading, setIsLoading] = useState(server.toLocaleUpperCase() === "EPIC");
	const [compact, setIsCompact] = useState(true);
	const [servers, setServers] = useState<string[][]>([]);
	const [areAllTeamsFull, setAreAllTeamsFull] = useState(false);
	const [isFilterInverted, setisFilterInverted] = useState(false);
	const [filters, setFilters] = useState<IRaidRankFilter>({
		includeStudents: [],
		excludeStudents: [],
		fullTeamsOnly: false
	});
	const [info, setInfo] = useState<IRaidInfo>({
		season: -1,
		playable: { unknown: 0},
		start: "0",
		end: "0",
		seasonType: -1
	});


	const keys = Object.keys(info.playable);
	const raidName = keys[0]?.split("_")[0] ?? "";
	const root = BUCKET_HOST+`/files/data/raid/${server.toLocaleLowerCase()}/${season.toString()}`;
	SCORE_CALCULATORS.setCalculators(RaidScore.fromRaid(server, parseInt(season), raidName));


	function processRaidReport() {
		let scores:number[] = [],
			times:number[] = [],
			timesCount:{[diff:string|number]: number[]} = {},
			timesMinMax:number[],
			levels:{[level:number]:number} = {},
			teamsUsed:number[] = [],
			displayedScores:number[] = []
			;
		
		for (const player of data.rows) {
			const diff = player.getDifficulty();
			timesCount[diff] = timesCount[diff] ?? initializeTimekeeping();
			scores.push(player.score);
			const timeScore = SCORE_CALCULATORS.getCalculators()[player.getDifficultyNumber()].calculateSecondsElapsed(player.score);
			times.push(timeScore);
			const timeId = Math.floor(timeScore);
			timesCount[diff][timeId]++;
			if (timesMinMax) {
				timesMinMax[0] = Math.min(timeId, timesMinMax[0]);
				timesMinMax[1] = Math.max(timeId, timesMinMax[1]);
			} else {
				timesMinMax = [timeId,timeId];
			}
			levels[player.level] = (levels[player.level] ?? 0) + 1;
		}
		for (const player of displayedData) {
			displayedScores.push(player.score);
			teamsUsed.push(player.teams?.length??0)
		}
		
		setScoreReport(new CommonRaidReportItem(scores,timesCount,timesMinMax,times, levels, teamsUsed, displayedScores))
	}

	function hasFilters() {
		return filters && (
			(filters.fullTeamsOnly && !areAllTeamsFull) || 
			(filters.minScore && filters.minScore > 0) || 
			(filters.maxScore && filters.maxScore > 0) || 
			(filters.minRank && filters.minRank > 0) || 
			(filters.maxRank && filters.maxRank > 0 && filters.maxRank <= data?.rows.length) || 
			filters.minDifficulty || 
			filters.maxDifficulty || 
			filters.sensei?.length || 
			filters.title?.length || 
			filters.includeStudents?.length || 
			filters.excludeStudents?.length || 
			filters.tiers?.length)
	}

	function filteredData(toFilter?: RaidRankTsv) {
		if(hasFilters()) {
			setIsLoading(true);
			// return data?.rows?.filter(applyFilter);
			const filtered = [];
			for (const row of (toFilter ?? data)?.rows ?? []) {
				if (applyFilter(row) !== isFilterInverted)
					filtered.push(row);
			}
			
			setIsLoading(false);
			return filtered;
		}

		return (toFilter ?? data)?.rows ?? [];
	}

	useEffect(() => {
		let ignore = false;
		async function loadInfo() {
			if (server.toLocaleUpperCase() === "EPIC") {
				return;
			}
			const result = await getJson(`${root}/info.json`);
			if (!ignore) {
				setInfo(result);
			}
		}

		loadInfo();

		return () => {
			ignore = true
		}
	}, [server, season])

	useEffect(() => {
		let ignore = false;
		async function loadData() {
			if (server.toLocaleUpperCase() === "EPIC") {
				const EPIC_SERVERS = ["as", "na", "eu", "twhkmc", "kr"]
				const fileListPath = BUCKET_HOST+'/files/data/fileList.json';
				const fileList = await getJson(fileListPath);
				let eRoot = "";
				const servers:{[s:string]: Promise<Tsv<RaidRankTsvRow>>} = {}
				const includedData:string[][] = []
				for (const eServer of EPIC_SERVERS) {
					try {
						const data = fileList.raid[eServer.toLocaleLowerCase()][season.toString()];
						if(!!!data) continue;
						const files = {};
						const regexMatcher = /\d{4}-\d{2}-\d{2}/g;
						for (const file in data) {
							const matches = file.match(regexMatcher);
							if (!!!matches)
								continue;
							files[matches[0]] = true;
						}
						const eDate = Object.keys(files).sort().reverse()[0];
						const temp = new RaidRankTsv();
						eRoot = BUCKET_HOST+`/files/data/raid/${eServer.toLocaleLowerCase()}/${season.toString()}`;
						const getTeamData = getJson(`${eRoot}/${eDate}-teams.json`)
						const getNameData = getJson(`${eRoot}/${eDate}-users.json`)
						const getTsv = temp.fromUrlWith(`${eRoot}/${eDate}-ranks.tsv`, {
							teams: await getTeamData,
							names: await getNameData
						});
						includedData.push([eServer, eDate]);
						servers[eServer] = getTsv;
					} catch { }
				}
				if (Object.keys(servers).length == 0) return;
				
				const eInfo = await getJson(`${eRoot}/info.json`);
				const result = await Promise.all(Object.values(servers));
				const merged = result.pop().mergeWith(...result);
				merged.rows = merged.rows.sort((a,b) => b.score - a.score).map((a,i) => {a.rank = i+1; return a;});
				
				if (!ignore) {
					setData(merged);
					setInfo(eInfo);
					setDisplayedData(filteredData(merged));
					setServers(includedData)
				}
				setIsLoading(false);

			} else {
				if (!!!date || "Select Data Source" === date) return;
				setIsLoading(true);
				const temp = new RaidRankTsv();
				const getTeamData = getJson(`${root}/${date}-teams.json`)
				const getNameData = getJson(`${root}/${date}-users.json`)
				const getTsv = temp.fromUrlWith(`${root}/${date}-ranks.tsv`, {
					teams: await getTeamData,
					names: await getNameData
				});
				const rawTsv = await getTsv;
				if (!ignore) {
					setData(rawTsv);
					setDisplayedData(filteredData(rawTsv));
				}
				setIsLoading(false);
			}
			
		}

		loadData();

		return () => {
			ignore = true;
		}
	}, [date])

	function handleChange(event) {
		if (!!!event.target.value)
			return;
		setDate(event.target.value);
	}

	const senseiRegex = useMemo(()=>{
		return new RegExp(regexEscape(filters.sensei ?? ""), "i");
	}, [filters.sensei])

	const titleRegex = useMemo(()=>{
		return new RegExp( regexEscape(filters.title ?? ""), "i");
	}, [filters.title])

	function applyFilter(row: RaidRankTsvRow) {
		if (!!filters.fullTeamsOnly) {
			if(!row.hasTeamData() && !areAllTeamsFull)
				return false;
		}
		if (filters.sensei?.length) {
			if (!senseiRegex.test(row.username))
				return false;
		}
		if (filters.title?.length) {
			if (!titleRegex.test(row.emblem))
				return false;
		}
		let minScore = filters.minScore ?? 0;
		if (filters.minDifficulty) {
			minScore = Math.max(RAID_MINSCORE[filters.minDifficulty], minScore);
		}
		if (row.score < minScore) 
			return false;
		let maxScore = filters.maxScore ?? CURRENT_MAX_SCORE;
		
		if (filters.maxDifficulty) {
			maxScore = Math.min(RAID_MAXSCORE[filters.maxDifficulty], maxScore);
		}
		if (row.score > maxScore)
			return false;
		
		// if (filters.maxScore) {
		// 	let maxScore;
		// 	if (typeof(filters.maxScore) === "number") {
		// 		maxScore = filters.maxScore
		// 	} else {
		// 		maxScore = RAID_MINSCORE[filters.maxScore];
		// 	}

		// 	if (row.score > maxScore)
		// 		return false;
		// }

		if (filters.tiers && filters.tiers.length > 0) {
			if (filters.tiers.indexOf(row.getTier()) < 0) {
				return false;
			}
		}

		if (filters.maxRank && row.rank > filters.maxRank)
			return false;
		if (filters.minRank && row.rank < filters.minRank)
			return false;

		if (filters.includeStudents && filters.includeStudents.length > 0) {
			for(const student of filters.includeStudents) {
				if (!row.findStudent(student))
					return false;
			}
		}
		if (filters.excludeStudents && filters.excludeStudents.length > 0) {
			for(const student of filters.excludeStudents) {
				if (row.findStudent(student))
					return false;
			}
		}

		return true;
	}

	const getExportData = () => {
		
		if (!!!displayedData.length) {
			return [];
		}
		const exportData = [displayedData[0].getHeaders().join("\t")+"\n"];
		for (const row of displayedData) {
			const rowData = row.export();
			for (const teamData of rowData) {
				exportData.push(teamData.join("\t")+"\n");
			}
		}
		return exportData;
	}
	const initializeTimekeeping = () => {
		return Array.apply(null, Array(3601)).map(function() { return 0 });
	}
	// TODO: thread worker this so it isn't blocking
	const generateAnalytics = () => {
		const totalPlayers = displayedData.length;
		RaidReportItem.totalPlayers = totalPlayers;
		if (filters.fullTeamsOnly) {
			let attendanceCount = {},
			assistantCount = {},
			senseisPerStudent = {},
			teamCount = {},
			teams = {},
			totalTeams = 0,
			students:{[id:string|number]: Student} = {},
			studentScores:{[id:string|number]: number[]} = {},
			studentTeamsUsed:{[id:string|number]: number[]} = {},
			teamTeamsUsed:{[id:string|number]: number[]} = {},
			teamScores:{[id:string|number]: number[]} = {},
			studentScatter: {[id:string|number]: IPoint[]} = {},
			studentAssistScatter: {[id:string|number]: IPoint[]} = {}
			;
			for (const player of displayedData) {
				let hasAssist = false,
					hasNoEmpty = true;
				const playerStudents = [];
				for (let i=0; i<player.teams.length; i++) {
					const team = player.teams[i];
					const teamKey = team.asKey();
					teams[teamKey] = team;
					teamCount[teamKey] = (teamCount[teamKey] ?? 0) + 1;
					teamTeamsUsed[teamKey] = teamTeamsUsed[teamKey] ?? [];
					teamTeamsUsed[teamKey].push(player.teams.length);
					teamScores[teamKey] = teamScores[teamKey] ?? [];
					teamScores[teamKey].push(player.score);
					totalTeams++;
					for (const student of [
						...team.strikers,
						...team.specials
					]) {
						playerStudents.push(student.getId());
						const studentId = student.getId();
						students[studentId] = student;
						if (student.isAssist) {
							hasAssist = true;
							studentAssistScatter[studentId] = studentAssistScatter[studentId] ?? [];
							studentAssistScatter[studentId].push({x: player.teams.length, y: player.score});
							studentAssistScatter[studentId+"_levelrank"] = studentAssistScatter[studentId+"_levelrank"] ?? [];
							studentAssistScatter[studentId+"_levelrank"].push({x: player.rank, y: student.level});
							studentAssistScatter[studentId+"_scorePosition"] = studentAssistScatter[studentId+"_scorePosition"] ?? [];
							studentAssistScatter[studentId+"_scorePosition"].push({x: i+1, y: player.score});
							assistantCount[student.id] = assistantCount[student.id] ?? {};
							assistantCount[student.id].total = (assistantCount[student.id].total ?? 0) + 1;
							assistantCount[student.id][student.getTier()] = (assistantCount[student.id][student.getTier()] ?? 0) + 1;
							// assistantCount[student.id]["Lv "+student.level] = (assistantCount[student.id]["Lv "+student.level] ?? 0) + 1;
							studentTeamsUsed[studentId] = studentTeamsUsed[studentId] ?? [];
							studentTeamsUsed[studentId].push(player.teams.length);
							studentScores[studentId] = studentScores[studentId] ?? [];
							studentScores[studentId].push(player.score);
						} else if (!student.isEmpty()) {
							studentScatter[studentId] = studentScatter[studentId] ?? [];
							studentScatter[studentId].push({x: player.teams.length, y: player.score});
							studentScatter[studentId+"_levelrank"] = studentScatter[studentId+"_levelrank"] ?? [];
							studentScatter[studentId+"_levelrank"].push({x: player.rank, y: student.level});
							studentScatter[studentId+"_scorePosition"] = studentScatter[studentId+"_scorePosition"] ?? [];
							studentScatter[studentId+"_scorePosition"].push({x: i+1, y: player.score});
							attendanceCount[student.id] = attendanceCount[student.id] ?? {};
							attendanceCount[student.id].total = (attendanceCount[student.id].total ?? 0) + 1;
							attendanceCount[student.id][student.getTier()] = (attendanceCount[student.id][student.getTier()] ?? 0) + 1;
							// attendanceCount[student.id]["Lv "+student.level] = (attendanceCount[student.id]["Lv "+student.level] ?? 0) + 1;
							studentTeamsUsed[studentId] = studentTeamsUsed[studentId] ?? [];
							studentTeamsUsed[studentId].push(player.teams.length);
							studentScores[studentId] = studentScores[studentId] ?? [];
							studentScores[studentId].push(player.score);
						} else if (hasNoEmpty) {
							attendanceCount["EMPTY"] = attendanceCount["EMPTY"] ?? {};
							attendanceCount["EMPTY"].total = (attendanceCount["EMPTY"].total ?? 0) + 1;
							studentTeamsUsed[studentId] = studentTeamsUsed[studentId] ?? [];
							studentTeamsUsed[studentId].push(player.teams.length);
							studentScores[studentId] = studentScores[studentId] ?? [];
							studentScores[studentId].push(player.score);
							hasNoEmpty = false;
						}
					}
				}
				for (const student of [...new Set(playerStudents)]) {
					senseisPerStudent[student] = senseisPerStudent[student] ?? {};
					senseisPerStudent[student].total = (senseisPerStudent[student]?.total??0)+1
				}
				if (!hasAssist) {
					assistantCount["EMPTY"] = (assistantCount["EMPTY"] ?? 0) + 1;
				}
			}
			const studentList = Object.values(students);
			const teamKeyList = Object.keys(teams);
			const participationReport:RaidStudentReportItem[] = [];
			const teamsReport:RaidTeamReportItem[] = [];
			RaidTeamReportItem.totalTeams = totalTeams;
			const filterWithoutStudent = (id) => {
				const teams:number[] = [];
				const score:number[] = [];
				for (const data of displayedData) {
					if (!data.findStudentById(id)) {
						score.push(data.score);
						teams.push(data.teams.length);
					}
				}

				return [score, teams];
			}
			for (const student of studentList) {
				const id = student.getId();
				const [scoreWithout, teamsWithout] = filterWithoutStudent(id);
				participationReport.push(new RaidStudentReportItem(
					student,
					attendanceCount[id] ?? {total:0},
					assistantCount[id] ?? {total:0},
					senseisPerStudent[id] ?? {total:0},
					studentScatter[id] ? [
						{label: "Score vs Unit # Found in", data: studentScatter[id+"_scorePosition"]},
						{label: "Score vs Units", data: studentScatter[id]},
						{label: "Level vs Rank", data: studentScatter[id+"_levelrank"]},
					] : [],
					studentAssistScatter[id] ? [
						{label: "Score vs Unit # Found in (Assist)", data: studentAssistScatter[id+"_scorePosition"]},
						{label: "Score vs Units (Assist)", data: studentAssistScatter[id]},
						{label: "Level vs Rank (Assist)", data: studentAssistScatter[id+"_levelrank"]},
					] : [],
				)
				.calculateScoreData(studentScores[id], scoreWithout)
				.calculateTeamData(studentTeamsUsed[id], teamsWithout)
				);
			}

			for (const key of teamKeyList) {
				teamsReport.push(new RaidTeamReportItem(
					key,
					teams[key],
					teamCount[key]
				)
				.calculateScoreData(teamScores[key], [])
				.calculateTeamData(teamTeamsUsed[key], [])
				)
			}
			

			participationReport.sort((a,b) => b.assist.total + b.attendance.total - (a.assist.total + a.attendance.total)).sort((a,b) => b.senseis.total - a.senseis.total);
			teamsReport.sort((a,b) => b.count - a.count);
			setDisplayedParticipationReport(participationReport);
			setStudentDataReport([...participationReport]
				.filter((a)=>a.score.meanWithout>0)
				.sort((a,b) => (b.score.meanWith - b.score.meanWithout) - ((a.score.meanWith - a.score.meanWithout)))
			)
			setTeamReport(teamsReport);
		} 
		processRaidReport();
		setIsReportVisible(true);
	}
	


	const handleSaveFile = async () => {
		const fileName = `${server}_${season}_export.tsv`;

		// const textRef = useRef<string>(); // Store your large text here
		// Create a Blob as a stream
		const textBlob = new Blob(getExportData(), { type: 'text/plain' });
		
		// Create a ReadableStream from the Blob
		const textStream = textBlob.stream();
		
		// Read the stream into a Uint8Array
		const chunks: Uint8Array[] = [];
		const reader = textStream.getReader();
		
		while (true) {
			const { done, value } = await reader.read();
			
			if (done) {
				break;
			}
			
			chunks.push(value);
		};
		// Concatenate Uint8Array chunks into one
		const combinedUint8Array = new Uint8Array(chunks.reduce((acc, chunk) => acc.concat(Array.from(chunk)), []));
	
		// Create a Blob from the Uint8Array
		const streamBlob = new Blob([combinedUint8Array], { type: 'application/octet-stream' });
	
		// Trigger the download link
		saveAs(streamBlob, fileName);
	}
// TODO: Move Modal to a different view instead but undfer same component
	return (
		<>
		<Alert variant="danger" dismissible hidden={["EPIC", "JP", "CN"].indexOf(server.toUpperCase()) >= 0}>
			<a href={`/Raid/EPIC/${season}`}>Click here</a> to view EPIC data.
		</Alert>
		<RaidReportModal compact={compact} show={isReportVisible} setShow={setIsReportVisible} reports={{
				attendance: displayedParticipationReport,
				team: teamReport,
				raid: scoreReport,
				studentStatistics: studentDataReport
			}} date={date} senseis={displayedData?.length ?? 0} fullUnits={filters.fullTeamsOnly} />
		<Row>
			<Col>
				<RaidTerrainImage name={raidName} terrain={keys[0]?.split("_")[1] ?? ""}>
					<Row>
						<Col md={2}>
							<label>Server</label>
						</Col>
						<Col>
							{server.toUpperCase()} {servers.map(s => (
								<a href={`/Raid/${s[0]}/${season}`}><TagBadge>{s[0]}: {s[1]}</TagBadge></a>
							))}
						</Col>
					</Row>
					<Row>
						<Col md={2}>
							<label>Season</label>
						</Col>
						<Col>
							<a href={`/Raid/${server}/${parseInt(season)-1}`}>&lt;&lt;</a>
							{info.season}
							<a href={`/Raid/${server}/${parseInt(season)+1}`}>&gt;&gt;</a>
						</Col>
					</Row>
					<Row>
						<Col md={2}>
							<label>Period</label>
						</Col>
						<Col>
							{info.start} - {info.end}
						</Col>
					</Row>
					<Row hidden={server.toUpperCase() === "EPIC"}>
						<Col md={2}>
							<label>Data Source</label>
						</Col>
						<Col>
							<RaidFileListDropDown onChange={handleChange}></RaidFileListDropDown>
						</Col>
					</Row>
					<RaidDataFilters filters={filters} setFilters={setFilters} updateView={() => {
						setDisplayedData(filteredData());
					}} />
					<Row>
						<Col md={2}>
						</Col>
						<Col>
							<div key="assume-teams-full" className="mb-3">
								<Form.Check 
								checked={areAllTeamsFull}
								type="switch"
								id="assume-teams-full"
								label="Assume incomplete team data are Full Units? (Wanpan)"
								onChange={(a)=>{
									setAreAllTeamsFull(a.target.checked);
								}}
								/>
							</div>
						</Col>
					</Row>
					<Row>
						<Col md={2}>
						</Col>
						<Col>
							<div key="invert-filter" className="mb-3">
								<Form.Check 
								checked={isFilterInverted}
								type="switch"
								id="invert-filter"
								label="Invert Filter Results?"
								onChange={(a)=>{
									setisFilterInverted(a.target.checked);
								}}
								/>
							</div>
						</Col>
					</Row>
					<Row>
						<Col md={2}>
						</Col>
						<Col>
							<div key="student-compact" className="mb-3">
								<Form.Check 
								checked={compact}
								type="switch"
								id="student-compact"
								label="Compact Mode"
								onChange={(a)=>{
									setIsCompact(a.target.checked);
								}}
								/>
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<Button variant="secondary" style={style.button} onClick={handleSaveFile} disabled={!!!displayedData?.length}>
								Export
							</Button>
							<Button variant="secondary" style={style.button} onClick={()=>{
								setDisplayedData(filteredData());
							}}>
								Apply Filters
							</Button>
							<Button variant="secondary" style={style.button} onClick={generateAnalytics} 
							disabled={isLoading || (date?.length == 0 || displayedData?.length == 0)}>
							{/* disabled={!filters.fullTeamsOnly || date?.length == 0 || displayedData?.length == 0}> */}
								Generate Analytics
							</Button>
							
						</Col>
					</Row>
				</RaidTerrainImage>
			</Col>
		</Row>
		<Row hidden={isLoading || isReportVisible}>
			<Col>
				<MyPagination params={{compact: compact}} RowAs={RaidLeaderboardRowRenderer} data={displayedData ?? []} itemsPerPage={20}></MyPagination>
			</Col>
		</Row>
		{/* <Sticky offset={70} z={9}> */}
		<Row  style={{alignContent: "center", marginLeft: "1em", marginTop: "1em"}}>
			<Col>
				<MyLoadingSpinner isLoading={isLoading} size={100}/>
			</Col>
		</Row>
		{/* </Sticky> */}
		{/* <span style={{
			position: "absolute",
			top: "50%",
			left: "50%"
		}}>
			
		</span> */}
		</>
	)
}