import MultiStudentPicker from "Components/MultiStudentPicker";
import StudentFilterItem from "Components/StudentFilterItem";
import { TagBadge } from "Components/TagBadge";
import IStyle from "Interfaces/IStyle";
import ImageGallery from "Modals/ImageGallery";
import { SchaleStudent } from "Models/Student";
import StudentFilter from "Models/StudentFilter";
import StudentMap from "Models/StudentMap";
import React, { useEffect, useMemo, useState } from "react";
import { Alert, Card, Col } from "react-bootstrap";
import { getJson } from "Utils/Common";

const KEYS = {
	ITEMS: "midokuni.tool.favor.items",
}
interface ISchaleItem {
	Id: number;
	Name: string;
	Desc: string;
	Category: string;
	Icon: string;
	Flavor: string;
	Effect: string;
	Rarity: string;
	Quality: number;
}

interface ISchaleGiftItem extends ISchaleItem {
	Category: "Favor";
	ExpValue: number;
	Tags: string[];
}
const styles:IStyle = {
	cardContainer: {
		display: "flex", 
		flexDirection: "row", 
		flexWrap: "wrap"
	},
	cardTitle: {
		overflow: "hidden",
		height: "2em",
	}
}
function GiftCard({data, students}: {data:ISchaleGiftItem, students:SchaleStudent[]}) {
	const icon = `https://schaledb.com/images/item/icon/${data.Icon}.webp`;
	const taggedStudents = students.filter(s=>[...s.FavorItemTags, ...s.FavorItemUniqueTags].filter(t=> data.Tags.indexOf(t) >= 0 ).length > 0);

	return (
		<Card className="text-pink gift card" key={data.Id} style={styles.card}>
			<Card.Header style={styles.cardTitle}>
				<Card.Title className="responsive-font">
					{data.Name}
				</Card.Title>
				{/* <Card.Subtitle>
					{taggedStudents.map(t => <TagBadge>{t.Name}</TagBadge>)}
				</Card.Subtitle> */}
			</Card.Header>
			<Card.Body>
				<Card.Img as={"span"}><ImageGallery title={data.Name} srcs={[icon]}></ImageGallery></Card.Img>
				{/* <Card.Text><ParagraphString>{data.Desc}</ParagraphString></Card.Text> */}
				{/* <Card.Text>
					<IconLink
						label="Link Here!"
						href={data.resourceUrl}
					/>
				</Card.Text> */}
			</Card.Body>
			<Card.Footer><Card.Text>{taggedStudents.map(s => <TagBadge>{s.getDisplayName()}: x{[...s.FavorItemTags, ...s.FavorItemUniqueTags].filter(t=> data.Tags.indexOf(t) >= 0 ).length}</TagBadge>)}</Card.Text></Card.Footer>
			{/* <Card.Footer><Card.Text>{data.languages.map(t => <TagBadge>{t}</TagBadge>)}</Card.Text></Card.Footer> */}
		</Card>)
}

export default function GiftChecker() {
	const studentDataset = useMemo<SchaleStudent[]>(()=>StudentMap.schaleStudent, []);
	const [filters, _setFilters] = useState<StudentFilter[]>(
		// [
		// 	"Hina", 
		// 	"Hina (Swimsuit)", 
		// 	"Hina (Dress)", 
		// 	"Kisaki",
		// 	"Yukari",
		// 	"Koyuki",
		// ]
		(JSON.parse(localStorage.getItem(KEYS.ITEMS)) ?? []).map(s=>new StudentFilter(s))
	);
	const setFilters = (toSet) => {
		_setFilters(toSet);
		localStorage.setItem(KEYS.ITEMS, JSON.stringify(toSet.map(f=>f.name)));
	}
	console.log(filters)
	const targetStudents = useMemo(()=>studentDataset.filter(s=>filters.filter(f=>f.name == s.name).length > 0), [filters]);
	const giftSorter = (a:ISchaleGiftItem,b:ISchaleGiftItem)=>{
		const taggedStudentsA = targetStudents.filter(s=>[...s.FavorItemTags, ...s.FavorItemUniqueTags].filter(t=> a.Tags.indexOf(t) >= 0 ).length > 0);
		const taggedStudentsB = targetStudents.filter(s=>[...s.FavorItemTags, ...s.FavorItemUniqueTags].filter(t=> b.Tags.indexOf(t) >= 0 ).length > 0);

		return taggedStudentsB.length - taggedStudentsA.length;
	}
	const [gifts, setGifts] = useState<ISchaleGiftItem[]>();
	useEffect(() => {
		let ignore = false;
		async function loadInfo() {
			const js = await getJson("https://schaledb.com/data/en/items.min.json");
			if (!ignore) {
				setGifts(Object.values(js).filter((i:any)=>i.Category === "Favor").map(i=>i as ISchaleGiftItem).sort(giftSorter));
			}
		}

		loadInfo();

		return () => {
			ignore = true
		}
	}, []);
	useEffect(() => {
		let ignore = false;
		if (!ignore && gifts) {
			setGifts([...gifts].sort(giftSorter));
			// setGifts([...gifts]);
		}
		return () => {
			ignore = true
		}
	}, [filters]);
	// console.log(targetStudents.map(s=>[s.Name, s.FavorItemTags, s.FavorItemUniqueTags]));

	return (<>
	<Alert variant="info" dismissible>
		As usual, this is just a a WIP but is functional.<br></br>
		This should let you know which gifts you need for your students.
	</Alert>
	<h2 className="text-pink">Aggregate Gift Checker</h2>
		<MultiStudentPicker 
			setTarget={(toSet) => {
				setFilters(toSet ?? []);
			}} 
			target={filters}
			pop={() => {
				const r =filters.pop()
				setFilters([...filters]);

				return r;
			}}
			push={(toAdd:string) => {
				if (filters.findIndex(f=>f.name == toAdd) < 0) {
					filters.push(new StudentFilter(toAdd));
					setFilters([...new Set(filters)]);
				}
			}}
		/>
		
		{filters?.map((student, index)=>(
			<StudentFilterItem 
			key={index}
			bg="violet"
			className="list-item student include"
			student={student}
			id={index}
			onClose={()=>{
				filters.splice(index, 1);
				setFilters([...filters]);
			}}></StudentFilterItem>
		))}
		<h4 className="text-pink">SR</h4>
		<Col style={styles.cardContainer}>
			{gifts?.filter(gift=>gift.Rarity === "SR").map(gift => {
				return (<GiftCard data={gift} students={targetStudents}></GiftCard>)
			})}
		</Col>
		<hr></hr>
		<h4 className="text-pink">SSR</h4>
		<Col style={styles.cardContainer}>
			{gifts?.filter(gift=>gift.Rarity === "SSR").map(gift => {
				return (<GiftCard data={gift} students={targetStudents}></GiftCard>)
			})}
		</Col>
	{/* <Button onClick={async ()=>{
		const js = await getJson("https://schaledb.com/data/en/items.min.json")
		console.log(js)
		const fv = Object.values(js).filter((i:any)=>i.Category === "Favor")
		console.log(fv)
	}}>Test</Button> */}
	</>);
}