import Student from "Models/Student";
import StudentMap from "Models/StudentMap";
export type RelationalOperator = ">=" | "=" | "<=";
export type InclusionOperator = "Any" | "Only" | "Excluded";
export type Mystic = "1☆" | "2☆" | "3☆" | "4☆" | "5☆" | "UE" | "3☆UE" | "2☆UE" | "1☆UE";
interface Relation<T> {
	operator: RelationalOperator;
	operand: T;
}
export default class StudentFilter extends Student {
	targetLevel: Relation<number>;
	targetMystic: Relation<Mystic>;
	targetUnit: Relation<number>;
	targetAssist: InclusionOperator;
	constructor(name:string) {
		super();
		const map = new StudentMap();
		this.name = map.getProperName(name);
		this.id = map.getId(this.name);
		this.targetLevel = {
			operator: ">=",
			operand: 1
		};
		this.targetMystic = {
			operator: ">=",
			operand: "1☆"
		};
		this.targetUnit = {
			operator: ">=",
			operand: 1
		};
		this.targetAssist = "Any";
	}
	getTargetMystic() {
		switch (this.targetMystic.operand) {
			case "3☆UE": return 8;
			case "2☆UE": return 7;
			case "1☆UE": 
			case "UE": return 6;
			case "5☆": return 5;
			case "4☆": return 4;
			case "3☆": return 3;
			case "2☆": return 2;
			case "1☆": return 1;
		}
	}
	matches(student:Student):boolean {
		if (this.getId() !== student.getId())
			return false;
		switch(this.targetLevel.operator) {
			case ">=":
				if (this.targetLevel.operand > student.level) return false;
				break;
			case "=":
				if (this.targetLevel.operand !== student.level) return false;
				break;
			case "<=":
				if (this.targetLevel.operand < student.level) return false;
				break;
		}
		if (student.unit > 0) {
			switch(this.targetUnit.operator) {
				case ">=":
					if (this.targetUnit.operand > student.unit) return false;
					break;
				case "=":
					if (this.targetUnit.operand !== student.unit) return false;
					break;
				case "<=":
					if (this.targetUnit.operand < student.unit) return false;
					break;
			}
		}
		const targetMystic = this.getTargetMystic();
		const studentMystic = student.getTierNumber();
		// console.log([targetMystic, studentMystic]);
		switch(this.targetMystic.operator) {
			case ">=":
				if (targetMystic >= 6 && !student.isUe) return false;
				if (targetMystic > studentMystic) return false;
				break;
			case "=":
				if (targetMystic >= 6 && !student.isUe) return false;
				if (targetMystic !== studentMystic) return false;
				break;
			case "<=":
				if (targetMystic < studentMystic) return false;
				break;
		}

		switch (this.targetAssist) {
			case "Only": if (!student.isAssist) return false;
				break;
			case "Excluded": if (student.isAssist) return false;
				break;
		}
		return true;
	}
	override toString() {
		// console.log(this);
		return this.getDisplayName();
	}
}